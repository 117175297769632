import React, {Component} from "react"
import Button from "@material-ui/core/Button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArrowForwardThinIcon from "@material-ui/icons/ArrowForward"
import {isValidContentfulImage, isValidSectionField} from "../common/utils/CommonUtils";
import {graphql} from 'gatsby';
import "./home-page.scss"
import "./learning-library.scss"
import BreadCrumbs from "../components/bread-crumbs";

const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    }

]

class Diversity extends Component {

    constructor(props) {

        super(props);
        this.state = {
            openModal: false,

            educationalContentItems: [],
            filteredEducationalItems: [],

            loader: {
                options: {
                    position: 'absolute'
                }
            }

        };

        this.pageData = this.getFirstContentfulNode(this.props,
            'allContentfulDeiPage');
    }

    getFirstContentfulNode = (props, key) => {
        return props.data[key].edges[0].node;
    };

    componentDidMount() {
        window.analytics.page("Diversity");
    }

    render() {
        const {beforeFooter, iconsWithText, shortSection, topsection} = this.pageData;

        return (
            <Layout location={this.props.location}>

                <SEO title="Diversity"/>
                <BreadCrumbs currentPage={"Diversity"} breadCrumbsList={breadCrumbsList}/>
                <div className="get-started-home-wrapper">
                    <div className="get-started-home-inner">
                        <div className="get-started-text-wrap">
                            <img className="get-started-home-pattern"
                                 src={require(
                                     '../assets/images/home-bg-circles.png')}
                                 alt="Pattern"/>
                            {
                                isValidSectionField(topsection, "title") &&
                                <h1 className="blackHeading2">{topsection.title}</h1>
                            }
                            {
                                isValidSectionField(topsection, "description")
                                &&
                                <p className="parah"
                                   dangerouslySetInnerHTML={{__html: topsection.description.description}}></p>
                            }
                        </div>
                        <div className="get-started-anim-wrap">
                            {
                                isValidContentfulImage(topsection.imageOrVideo)
                                && (
                                    <img className="get-started-home-hero-img"
                                         src={topsection.imageOrVideo?.file?.url}
                                         alt="providerImg"/>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className="new-home-features">
                    <div className="new-home-features-inner">

                        {
                            shortSection && shortSection.map(
                                (section, index) => (
                                    <div key={"key-" + section.heading}
                                         className="new-home-single-feature">
                                        {
                                            (index === 0 || index % 2 === 0)
                                            && (
                                                <div
                                                    className="new-feature-img-side">
                                                    <img
                                                        className="new-feature-image"
                                                        src={section.image.file.url}
                                                        alt=" Frame"/>
                                                </div>
                                            )
                                        }
                                        <div className="new-feature-text-side">
                                            {
                                                isValidSectionField(section,
                                                    "heading") &&

                                                <h2 className="feature-heading">
                                                    {section.heading}
                                                </h2>
                                            }

                                            {

                                                isValidSectionField(section,
                                                    "description") &&

                                                <p className="feature-para"
                                                   dangerouslySetInnerHTML={{__html: section.description.description}}></p>
                                            }

                                        </div>
                                        {
                                            index % 2 !== 0 && (
                                                <div
                                                    className="new-feature-img-side"
                                                    style={{textAlign: "right"}}>
                                                    {isValidContentfulImage(
                                                        section.image) && (
                                                        <img
                                                            className="new-feature-image"
                                                            src={section.image.file.url}
                                                            alt=" Frame"/>
                                                    )}

                                                </div>
                                            )
                                        }
                                    </div>
                                ))
                        }
                    </div>
                </div>

                <div className="dei-wrapper">
                    <div className="dei-inner">
                        <div className="dei-list">
                            {
                                iconsWithText && iconsWithText.map((iconsWithText) => (
                                    <div className="single-dei">
                                        <img className="dei-icon"
                                             src={iconsWithText.image.file.url}
                                             alt="Target Icon"/>
                                        <p className="dei-text">{iconsWithText.name}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className="new-home-letter-one">
                    <div className="new-home-letter-one-inner">
                        <img className="letter-one-image"
                             src={beforeFooter.image.file.url}

                             alt="home letter"/>
                        <h2 className="home-letter-one-heading">{beforeFooter.heading}</h2>
                        <p className="home-letter-one-para" dangerouslySetInnerHTML={{__html: beforeFooter.description.description}}></p>
                        <Button href={beforeFooter.link}
                                className="new-blue-link" color="primary">
                            {beforeFooter.linkText} <ArrowForwardThinIcon/>
                        </Button>
                    </div>
                </div>

            </Layout>
        )
    }
}

export default Diversity

export const query = graphql`
query getDeiPageData {
  allContentfulDeiPage {
    edges {
      node {
        topsection {
          title
          description {
            description
          }
          imageOrVideo {
            title
            description
            file {
              url
              fileName
              contentType
            }
          }
        }
        shortSection {
          heading
          description {
            description
          }
          image {
            title
            description
            file {
              url
              fileName
              contentType
            }
          }
          alignment
          linkText
          link
        }
        iconsWithText {
          name
          description
          image {
            title
            description
            file {
              url
              fileName
              contentType
            }
          }
        }
        beforeFooter {
          heading
          description {
            description
          }
          image {
            title
            description
            file {
              url
              fileName
              contentType
            }
          }
          link
          linkText
        }
      }
    }
  }
}


`;
